@use "../../../assets/styleUtils/index" as *;
#test-table-xls-button {
  color: white;
  background-color: #369fe7;
  width: 84px;
  margin-left: 8px;
  padding: 0.6rem 1.3rem;
  border-radius: 5px;
}
.each-user-pg {
  .profile-header {
    @include flex($justify: space-between);
    .left {
      @include flex($justify: flex-start);
    }
    .switch-wrap {
      .MuiTypography-root {
        color: #e0245e;
        font-size: 0.8rem;
        font-weight: 500;
      }

      .switch {
        width: 32px;
        height: 20px;
        padding: 0;
        margin-right: 1rem;
        .MuiSwitch-switchBase {
          padding: 0;
          margin: 2px;
          transition-duration: 300ms;
          &.Mui-checked {
            transform: translateX(13px);
            color: #fff;
            & + .MuiSwitch-track {
              opacity: 1;
              border: 0;
              background-color: var(--primary);
            }
            &.Mui-disabled + .MuiSwitch-track {
              opacity: 0.5;
            }
          }
        }
        .MuiSwitch-thumb {
          box-sizing: border-box;
          width: 15px;
          height: 15px;
          box-shadow: none;
          margin-top: 1px;
          color: white;
        }
        .MuiSwitch-track {
          border-radius: 16px;
          opacity: 1;
          background-color: #e6e6e6;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: flex-end;
  font-size: 0.8rem;
  > span,
  .item {
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
}

.details-wrap {
  @include flex($justify: space-between);
  flex-wrap: wrap;
  .details {
    width: calc(50% - 2rem);
    margin: 1rem 0;
    @include mq(mobile) {
      width: 100%;
    }
    word-wrap: break-word;
    &.w-full {
      width: 100%;
    }
  }
}

.marker {
  margin-right: 0.5rem;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #369fe7;
  &.success {
    background-color: #66cb9f;
  }
  &.warning {
    background-color: #f7936f;
  }
  &.error {
    background-color: #f16063;
  }
}
