@mixin button {
  padding: 10px 18px;
  border: none;
  cursor: pointer;
  border-radius: 28px;
  display: flex;
  font-size: 1em;
  max-height: 50px;
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  transition: background-color 0.5s;
  &:focus {
    outline: none;
    box-shadow: 0px 2px 4px rgba(5, 39, 105, 0.1),
      0px 4px 6px rgba(5, 39, 105, 0.15);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
