@use "../../assets/styleUtils/" as *;
.uploadFile {
  margin: 10px 0;
  display: flex;
  align-items: center;

  .avatar {
    width: 100px;
    height: 100px;
    position: relative;
    @include flex();
    @include mq(tablet) {
      width: 70px;
      height: 70px;
    }
    .img {
      border-radius: 50%;
      position: absolute;
      top: 0;
      inset: 0;
      width: 100%;
      height: 100%;
    }
    .loader {
      position: relative;
    }
    // object-fit: cover;
  }
  .right {
    margin-left: 2rem;
    p {
      margin-top: 0.5rem;
    }
  }
}
