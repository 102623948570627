@use "../../../assets/styleUtils/index" as *;

.pd_admin_login {
  text-align: center;
  .mid {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--textClr-80);
    margin: 16px 0;
  }
  .google {
    border-radius: 32px;
    background-color: var(--primary);
    width: 194px;
    height: 46px;
    color: var(--whiteClr);
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;
    border: none;
    svg {
      margin-right: 5px;
    }
  }
  .input-icon-wrap,
  .select-mui,
  button {
    border-radius: 12px !important;
    fieldset {
      border-radius: 12px !important;
    }
  }
}
