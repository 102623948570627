@use "../../assets/styleUtils/index" as *;

.image-modal {
  img {
    width: 100%;
    height: 100%;
    // min-height: 300px;
    object-fit: contain;
  }
}
.chatBox {
  width: 100%;
  height: 100%;
  flex-grow: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  .more {
    .drop-down-content {
      width: fit-content;
      padding: 0.5rem 0;
      text-align: left;
      min-width: 130px;
      button {
        padding: 0.2rem 1rem;
        color: black;
        text-transform: capitalize;
      }
    }
  }
  .flex {
    display: flex;
    align-items: center;
    p {
      text-transform: capitalize;
    }
  }
  .chatDetails {
    width: 100%;
    padding: 22px 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back-btn {
      display: none;
      margin-left: 0 !important;
    }
    @include mq(tablet) {
      padding: 1rem;
      .back-btn {
        display: inline-flex;
      }
    }
    .userDetail {
      display: flex;
      align-items: center;
      // img {
      //   width: 31px;
      //   height: 31px;
      //   border-radius: 50%;
      //   margin-right: 18px;
      // }
      .textPart {
        margin-left: 18px;
        p.name {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #4a4a4a;
          margin-bottom: 5px;
        }
        p.userActive {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 14px;
          color: #6e768c;
          span {
            background: #42be65;
            width: 8px;
            display: block;
            height: 8px;
            margin-right: 3px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .line {
    border-bottom: 0.5px solid #eaedf3;
    width: 100%;
  }
  .allMessageBox {
    flex-grow: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 29px;
    overflow: auto;
    @include webkit;
    @include mq(mobile) {
      padding: 20px 0.9rem;
    }
    .msg-wrap {
      display: flex;
      justify-content: flex-start;
      &.left {
        justify-content: flex-end;
      }
    }
    .eachMsg {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
      width: fit-content;
      max-width: 300px;
      .offer_card {
        padding: 1rem;
        margin-bottom: 0.5rem;
        border-radius: 10px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
        background: #ffffff;
        max-width: 400px;
        .text {
          padding: 0.4rem 0;
          color: var(--primary);
        }
        .btn-wrap {
          margin-top: 0.5rem;
          button {
            width: 48%;
          }
        }
      }
      img {
        width: 160px;
        height: 160px;
        border-radius: 5px;
        object-fit: cover;
        cursor: pointer;
      }
      &.left {
        font-size: 12px;
        line-height: 20px;
        justify-content: flex-end;
        align-items: flex-end;
        float: right;
        p.msg {
          color: #51606d;
          background: #eaeff4;
          border-radius: 15px 15px 0 15px;
        }
        .offer_card .text {
          padding: 0rem;
        }
      }
      .time {
        width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px;
        span {
          font-size: 10px;
          line-height: 20px;
          color: #323e4a;
        }
      }
      p.msg {
        background: var(--primary);
        border-radius: 15px 15px 15px 0;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        padding: 5px 16px;
        max-width: 346px;
      }
    }
  }
  .chat-footer {
    border-top: 1px solid #eaedf3;
    width: 100%;
    form {
      display: flex;
      align-items: center;
      padding: 10px 30px;
      justify-content: space-between;
      @include mq(mobile) {
        padding: 10px 16px;
      }
      .form-group {
        flex-grow: 2;
        .input-icon-wrap {
          border: none;
        }
      }
      .round-btn {
        background: none;
        border: none;
      }
      .sendBtn {
        height: 40px;
        justify-content: center;
        width: 109px;
        @include mq(mobile) {
          width: fit-content;
          min-width: unset;
          padding: 10px 5px;
          padding-right: 10px;
          span {
            display: none;
          }
          svg {
            margin: 0 !important;
          }
        }
        .sendIcon {
          margin-left: 10px;
        }
      }
    }
  }
}
