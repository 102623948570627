@use "../../assets/styleUtils/index" as *;

.dashboard-layout {
  width: 100%;
  .admin-search,
  .search-input {
    min-width: 280px;
  }
  .content-side {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    background: #f6f7fb;
    box-shadow: -4px 4px 14px rgba(224, 224, 224, 0.69);
    position: relative;
    .input-icon-wrap,
    .select-mui {
      border-radius: 12px !important;
      fieldset {
        border-radius: 12px !important;
      }
    }
    main {
      flex: auto;
      flex-grow: 2;
      min-height: calc(100vh - 120px);
      padding: 1rem;
      padding-top: 0;
      width: calc(100% - 250px);
      .pg-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        margin-bottom: 0.5rem;
      }
    }
  }
}
