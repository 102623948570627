.table {
  margin-top: 1rem;

  &.outline {
    thead {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0%;
        inset: 0;
        left: -0.5rem;
        background: transparent;
        width: 100%;
        padding: 0 0.5rem;
        display: block;
        border: 1px solid #e2e8f0;
        border-radius: 12px;
        height: 100%;
      }
    }
    tbody::before {
      background: transparent !important;
    }
  }
  .paper {
    min-height: 55vh;
    box-shadow: none;
    background-color: white;
    border-radius: 12px;
    padding: 0rem 1rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .td-checkbox {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  thead {
    th:first-child {
      border-radius: 12px 0 0 12px;
    }
    th:last-child {
      border-radius: 0 12px 12px 0;
    }
    th {
      padding: 0.4rem 1rem;
      background-color: #fff;
      font-weight: 500;
      color: #425466;
      border-bottom: 0px solid #e2e8f0;
      // border-top: 1px solid #e2e8f0;
    }
  }
  td:first-child,
  .pd-checkbox {
    padding-left: 0 !important;
  }
  tbody {
    border-radius: 12px;
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: -1rem;
      content: "@";
      background: #f6f7fb;
      width: calc(100% + 2rem);
      display: block;
      line-height: 10px;
      text-indent: -99999px;
    }
    td {
      padding: 0.5rem 1rem;
      font-weight: 500;
      width: max-content;
    }
    tr {
      &:first-child td {
        padding-top: 1rem;
      }
      &:last-child td {
        border-bottom: none;
      }
    }
  }
}
