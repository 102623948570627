@use "../../assets/styleUtils" as *;

.pd-chat {
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
  .topicPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  }
  .file-msg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: italic;
    font-weight: 500;
    // opacity: 0.8;
    svg {
      margin-right: 0.6rem;
      width: 0.9rem;
      height: 0.9rem;
    }
  }
  .whiteContainer {
    @include whiteContainer;
    padding: 0;
    display: flex;
    height: 700px;
    position: relative;
    .messageList {
      width: 30%;
      background: #ffffff;
      border-radius: 9px;
      border-right: 1px solid #eaedf3;
      padding-bottom: 20px;
      @include mq(tablet) {
        border-right: 0px solid #eaedf3;
        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
        width: 100%;
        position: absolute;
        left: 0;
        height: 100%;
        right: 0;
        z-index: 5;
      }
      .messageTop {
        padding: 36px 21px;
        .new {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            letter-spacing: 0.666667px;
            color: #4a4a4a;
          }
          button {
            background: #ebedfb;
            border-radius: 3px;
            padding: 6px 10px;
            font-weight: 600;
            font-size: 10px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #0052bd;
            border: none;
            cursor: pointer;
          }
        }
        .search {
          width: 100%;
          border: 1px solid #eaeaea;
          box-sizing: border-box;
          height: 40px;
          border-radius: 21px;
          margin-top: 22px;
          margin-bottom: 32px;
          &::placeholder {
            font-size: 14px;
            line-height: 21px;

            color: #adadad;
          }
        }
        .recent {
          font-weight: 600;
          font-size: 14px;
          line-height: 10px;
          /* identical to box height, or 71% */

          color: #323e4a;
        }
      }
      .msgDiv {
        height: 74%;
        overflow-y: auto;
        @include webkit;
      }
    }
    .chatBoxDiv {
      width: 70%;
      &.notadmin {
        @include mq(tablet) {
          background-color: white;
          width: 100%;
          position: absolute;
          left: 0;
          height: 100%;
          right: 0;
          transform: translateX(150%);
          z-index: 8;
          transition: transform 0.5s ease;
          &.active {
            transform: translateX(0);
          }
        }
      }
    }
  }
}
