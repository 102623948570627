@use "../../assets/styleUtils/" as *;

.pd-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 60;
  overflow: hidden;
  @include flex();
  .close-modal-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include mq(mobile) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  .title {
    margin-bottom: 1rem;
    color: black;
    font-size: 1rem;
  }
  .popBox2 {
    @include modalLay;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 420px;
    @include mq(mobile) {
      padding: 1.5rem 1rem;
    }
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
      button {
        min-width: 118px;
      }
    }
    .input-icon-wrap,
    .select-mui {
      background-color: #f7fafc;
    }
  }
  .msg-modal {
    min-height: 380px;
    .icon-wrap {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      @include flex();
      svg {
        font-size: 2.5rem;
      }
    }
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    .title {
      font-size: 1.2rem;
      margin: 0;
      font-weight: 600;
    }
    button {
      margin-top: 1rem;
    }
  }
}
