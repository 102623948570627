@use "../../assets/styleUtils/index" as *;

.authLayout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 10px;
  .logo {
    width: 200px;
    margin: auto;
    @include mq2(500px) {
      width: 150px;
    }
  }
  .title,
  .titleSub {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: var(--text-100);
    margin-top: 35px;
    @include mq2(500px) {
      font-size: 18px;
    }
  }
  .titleSub {
    font-weight: normal;
    margin-top: 19px;
    font-size: 16px;
    line-height: 20px;
    @include mq2(500px) {
      font-size: 13px;
    }
  }
  .midBox {
    border: 1px solid #cbd5e0;
    border-radius: 15px;
    width: 100%;
    max-width: 500px;
    padding: 57px 37px;
    margin-top: 36px;
    &.ext {
      margin-top: 0;
    }
    @include mq2(768px) {
      width: 70%;
      padding: 57px 20px;
    }
    @include mq2(768px) {
      width: 90%;
    }
  }
  p.sucMsg {
    text-align: center;
    width: 25%;
    padding: 16px;
    background: #e9f7ef;
    box-shadow: inset 3px 0px 0px rgba(39, 84, 174, 0.64);
    border-radius: 5px;
    border-left: 1px solid #66cb9f;
    color: #27ae60;
    margin-bottom: 27px;
    margin-top: 35px;

    font-size: 14px;
    line-height: 19px;
    @include mq2(768px) {
      width: 65%;
    }
    @include mq2(768px) {
      width: 85%;
    }
  }
  .btn,
  .btn-primary {
    margin-top: 1rem;
  }
}
