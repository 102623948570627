@use "../../../assets/styleUtils/index" as *;
.pd_home {
  .summary_box {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 29px;
    padding: 31px 45px;
  }
  .chatSide {
    width: 100%;
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    .barChart {
      width: 70%;
      background: #ffffff;
      border-radius: 29px;
      padding: 30px;
      .topicFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p {
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #27272e;
        }
        .selectyear {
          background: #e2e8f0;
          border-radius: 12px;
          background: #e2e8f0;
          border-radius: 12px;
          width: 120px !important;
          flex-grow: unset;
          padding: 9px 16px;
        }
      }
    }
    .list {
      width: 28%;
      background: #ffffff;
      border-radius: 29px;
      padding: 31px 29px;
    }
  }

  .tableSide {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 39px;
    margin-top: 30px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 38px;
    }
  }
}
