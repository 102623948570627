@use "../../../../assets/styleUtils/index" as *;

.eachBox {
  width: 24%;
  display: flex;
  justify-content: center;
  &.border {
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
  }
  &.left {
    border: none;

    border-right: 1px solid #e2e8f0;
  }
  .left {
  }
  .right {
    margin-left: 7px;
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #27272e;
      margin-bottom: 20px;
    }
    .number {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #27272e;
    }
  }
}
.pd_dispute_card {
  width: 100%;

  .topic {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #27272e;
  }
  .eachDisp {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 24px;
    .left {
      display: flex;
      align-items: center;
      p {
        font-weight: 500;
        margin-left: 19px;
        font-size: 14px;
        line-height: 19px;
        color: #27272e;
      }
    }
    .time {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #718096;
    }
  }
}

.pd_recent {
  width: 100%;
  table {
    border: none;
    width: 100%;
    tr {
      border: none;
      td {
        border: none;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        color: #000000;
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          /* identical to box height */

          text-decoration-line: underline;

          /* Blue 1 */

          color: #2f80ed;
        }
        .flex {
          display: flex;
          align-items: center;
          p {
            margin-left: 7px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            /* identical to box height */

            color: #000000;
          }
        }
      }
    }
  }
}
