@use "../../assets/styleUtils/" as *;

.card {
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
    0px 0px 1px rgba(50, 50, 71, 0.2);
  border-radius: 9px;
  position: relative;
  width: 100%;
  .setting_icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: fit-content;
    .avatar-group {
      display: flex;
      svg {
        width: 40px;
        height: 40px;
      }
      .more {
        margin-left: -1rem;
        background-color: #edf2f7;
        color: black;
        border: 1px solid #cbd5e0;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        font-weight: 500;
        @include flex();
      }
    }
  }
  .actions {
    .action {
      padding: 0.3rem 1rem;
      color: var(--primary) !important;
    }
    .drop-down-content {
      padding: 0.5rem;
      button,
      a {
        padding: 0.3rem 0.5rem;
      }
    }
  }
  .title {
    padding-top: 1rem;
    text-transform: capitalize;
  }
  .card_color_bar {
    width: 100%;
    height: 7px;
    border-radius: 9px 9px 0 0;
    // background-color: #66cb9f;
  }
  p {
    font-size: 0.9rem;
    margin: 0.4rem 0;
  }
  P.bold {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
  .flex {
    @include flex($justify: space-between);
    margin-top: 2rem;
    width: 100%;
  }
  .card_body {
    padding: 1rem;
    width: 100%;
  }
  .card_footer {
    padding: 1rem;
    border-top: 1px solid #edf2f7;
    @include flex($justify: space-between);
  }
}
