.submit-btn {
  display: flex;
  justify-content: center;
  height: 46px;
  color: white;
  background-color: var(--primary);
  margin: 10px 0;
  align-items: center;
  border: none;
  border-radius: 32px;
  width: 165px;
  margin: auto;
}
div.spin {
  width: 20px;
  height: 20px;
  color: transparent;
  border-radius: 50%;
  animation: spin 0.8s infinite linear;
  border: 2px solid transparent;
  border-top: 2px solid var(--whiteClr);
  border-left: 2px solid var(--whiteClr);
  border-bottom: 2px solid var(--whiteClr);
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
