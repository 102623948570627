@use "../../assets/styleUtils/" as *;

.nav_bar {
  padding: 1.5rem 0;
  background-color: #fff;
  position: relative;
  @include mq(mobile) {
    padding-top: 1rem;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0 !important;
  }
  .logo {
    width: 190px;

    @include mq(tablet) {
      display: none;
    }
  }
  .logo-mb {
    height: 30px;
    display: none;
    @include mq(tablet) {
      display: block;
    }
  }
  .nav_links_wrap {
    display: flex;
    justify-content: flex-end;
  }
  .menu-btn {
    display: none;
    @include mq(mobile) {
      display: flex;
    }
  }
  .nav_links {
    display: flex;
    justify-content: flex-end;
    .nav_link {
      color: #425466;
      &.active_link p {
        color: #000000;
        font-weight: 600;
      }
    }
    @include mq(mobile) {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      z-index: 30;
      flex-direction: column;
      padding: 0.5rem 0.2rem;
      background-color: #fff;
      clip-path: inset(0 0 100% 0);
      transition: clip-path 0.5s ease;
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #fafafa;
      &.show-nav {
        clip-path: inset(0 0 0 0);
      }
      .nav_link p {
        font-size: 0.9rem;
      }
    }
  }
  .profile-drop-down {
    .profile-action {
      @include mq(tablet) {
        .avatar {
          width: 30px;
          height: 30px;
        }
        span {
          margin: 0;
        }
        p {
          display: none;
        }
      }
    }
    .drop-down-content {
      button,
      a {
        text-transform: unset;
        padding: 0.3rem 0.7rem;
        &:last-child {
          padding-bottom: 0;
          padding-top: 0.5rem;
        }
      }
    }
  }
}
