.pagination-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.2rem 0;
  margin-left: 1rem;
  p {
    font-size: 14px;
    color: #718096;
  }
  button {
    font-size: 14px;

    &.Mui-selected {
      background-color: var(--primary);
      color: white;
    }
  }
  li {
    padding: 0;
    margin: 0;
  }
}
