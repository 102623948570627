@use "../../assets/styleUtils/index" as *;
.pg_verify {
  min-height: 100vh;

  .inner {
    width: 100%;
    padding: 1rem;
    padding-bottom: 0rem;
    max-width: 1200px;
    margin: auto;
    &.tabs-inner {
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  .setting-tabs {
    display: none;
    @include mq(tablet) {
      display: flex;
    }
  }
  .bg-whiteClr .inner {
    @include mq(mobile) {
      padding: 0 0.5rem;
      padding-top: 0.5rem;
    }
  }
  > .inner {
    padding-bottom: 2rem;
  }
  .nav-wrap {
    position: sticky;
    top: 0;
    z-index: 50;
  }
  .nav_bar {
    padding-bottom: 0;
  }
  .back-btn {
    @include mq(mobile) {
      padding: 0.5rem !important;
      min-width: 40px !important;
      border: none !important;
      .MuiButton-startIcon {
        margin: 0;
        @include flex();
      }
      span.text {
        display: none;
      }
    }
  }
  .MuiTab-root {
    text-transform: capitalize;
  }
  .MuiTabs-indicator {
    height: 7px;
    @include mq(mobile) {
      height: 4px;
    }
  }

  .flex-nav {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    @include mq(mobile) {
      padding-bottom: 0.5rem;
    }
    .title {
      margin: auto;
      text-transform: capitalize;
      @include mq(mobile) {
        font-size: 1.2rem;
      }
    }
  }
  .border-nav {
    border-bottom: 1px solid #e2e8f0;
  }
}

.search-input {
  background-color: transparent;
  padding: 0;

  input,
  .input-icon-wrap {
    background-color: transparent;
    border: none !important;
    &:focus {
      border: none;
    }
    .icon svg {
      width: 20px;
      height: 20px;
    }
  }
}
