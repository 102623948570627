@use "./assets/styleUtils/colors";
@use "./assets/styleUtils/layout";
@use "./assets/styleUtils/" as *;
@import "react-redux-toastr/src/styles/index";

html {
  scroll-behavior: smooth;
  font-family: "Manrope", sans-serif !important;
}
* {
  font-family: "Manrope", sans-serif !important;
}
.demo {
  form {
    padding: 1rem;
  }
  button {
    margin: 0.5rem;
  }
}

button,
label.MuiButton-root {
  @include mq(mobile) {
    font-size: 0.75rem !important;
  }
}
// toastr custom styles
.redux-toastr {
  position: relative;
  .top-center {
    width: 90vw;
    max-width: 600px;
    transform: translateX(-25%);
    padding-top: 40px;
    @include mq(mobile) {
      padding-top: 0;
      transform: translateX(3%);
    }
  }
  .toastr {
    opacity: 1;
    background-color: white;
    width: 100%;
    &::after {
      // content: "";
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.404);
    }
    > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      z-index: 50;
      > div {
        float: unset !important;
        margin: 0 !important;
        position: relative;
      }
    }
    span {
      color: var(--whiteClr) !important;
    }
    &.rrt-success {
      background-color: var(--primary);
    }
    &.rrt-error {
      background-color: var(--red-100) !important;
    }
    .rrt-left-container {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: static;
      @include flex();
      * {
        position: static !important;
        @include flex();
        margin: 0 !important;
      }
    }
    .rrt-middle-container {
      margin-left: 0 !important;
      @include flex($direction: column, $align: flex-start);
      height: 70px;
      flex-grow: 2;
      width: auto !important;
    }
    .rrt-right-container {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .close-toastr {
        width: 100%;
      }
    }
    svg {
      width: 28px !important;
      position: relative;
      height: 28px !important;
    }
  }
  .close-toastr.toastr-control {
    opacity: 1;
  }
}

// more actions styles
.drop-down-wrapper.more-actions {
  .drop-down-content {
    min-width: 125px;
    padding: 0.5rem 0;
    button,
    a.btn-noBg {
      text-decoration: none;
      cursor: pointer;
      font-size: 0.9em;
      text-transform: capitalize;
      padding: 0.5rem 0.5rem;
      border-radius: 0;
      font-weight: 500;
      background: transparent;
      border: none;
      @include flex($align: flex-end);
      svg {
        font-size: 18px;
        margin-right: 0.4rem;
        margin-top: -5px;
      }
      // &:last-child {
      //   margin-top: 0.5rem;
      // }
      &:hover {
        background: rgba(0, 0, 0, 0.04) !important;
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

ul {
  list-style-type: none;
}

body {
  background-color: var(--bg-color);
  font-size: 14px;
  h1,
  h2,
  h3,
  p,
  h4,
  h5,
  h6,
  svg,
  a,
  input {
    color: var(--textClr);
  }
}
button {
  cursor: pointer;
  &:hover {
    box-shadow: none !important;
  }
}
.btn {
  @include button;
}
.Mui-Button,
.MuiListItemButton-root {
  border-radius: 2rem !important;
}
.btn-primary {
  @include button;
  background-color: var(--primary);
  color: var(--whiteClr);
}
.btn-secondary {
  @include button;
  background-color: transparent;
  border: 1px solid var(--primary);
}
.btn-wrap {
  @include flex($justify: flex-start);
  margin-top: 0.6rem;
  // row-gap: 1rem;
  button:last-child {
    margin-left: 1rem;
  }
}

.form-group.sort-select {
  width: 152px;
  background-color: transparent;
  border: none;
  .MuiSelect-select {
    padding: 0.5rem 0;
  }
  * {
    padding: 0rem;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font-weight: 600;
  }
}
table {
  th {
    font-weight: 600 !important;
  }
}
