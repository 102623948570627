.loader-line {
  width: 100vw;
  height: 6px;
  position: fixed;
  overflow: hidden;
  background-color: #ddd;
  z-index: 100000;
  top: 0px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &::before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 100%;
    background-color: #fd5444;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
}
@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
