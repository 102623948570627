@use "../../assets/styleUtils/" as *;

.input-icon-wrap {
  background: #fafbfc;
  /* Neutral (Light) / N40 */

  box-sizing: border-box;

  background: var(--whiteClr);
  width: 100%;
  border-radius: 4px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &.rounded {
    border-radius: 30px;
  }
  &.icon-placeholder {
    input {
      padding-left: 45px !important;
    }
  }

  &.select-wrap {
    .icon {
      cursor: auto;
      color: var(--primary);
      pointer-events: none;
      padding: 0 0.1rem;

      svg {
        fill: var(--primary);
        width: 18px;
        height: 18px;
      }
    }
    select {
      padding-right: 1.3rem !important;
      cursor: pointer;
    }
  }
  input,
  .input select {
    height: 100% !important;
  }
}

// create a div with classname of form-group-wra to arrange input in 2's
.form-group-wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .form-group {
    width: 48%;
  }
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  width: 100%;

  label {
    color: var(--textClr-80) !important;
    margin-bottom: 8px;
    font-weight: 500;
    @include flex($justify: space-between);
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  .icon {
    background: transparent;
    position: absolute;
    top: 0;
    padding: 0 0.8rem;
    bottom: 0;
    border: none;
    font-size: 12px;
    background-color: inherit;
    z-index: 2;
    height: 90%;
    margin: 0.1rem;
    margin-right: 19px;
    @include flex();
    svg {
      width: 14px;
      height: 14px;
    }
    color: var(--textClr);
    &:disabled {
      pointer-events: none;
      cursor: text;
    }
    &.startIcon-left {
      left: 0;
      border-radius: 30px 0 0 30px;
    }
    &.startIcon-right {
      right: 0;
      border-radius: 30px 0 0 30px;
    }
    &.icon-right {
      right: 0;
      cursor: pointer;
      margin-right: 0.3rem;
      border-radius: inherit;
    }
  }
  &.half-width {
    width: 48%;
  }
}
.filter-select {
  width: fit-content;
  max-width: 197px;
  text-transform: capitalize !important;
}
.custom-checkbox {
  border: 1px solid var(--inputBorder);
  margin: 0.4rem 0 !important;
  margin-right: 1rem !important;
  padding-left: 0.6rem;
  border-radius: 4px;
  span {
    font-size: 14px;
  }
  svg {
    font-size: 18px;
  }
  &.active {
    border-color: var(--primary);
  }
}
/*custom select arrow*/
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.upload-pdf {
  width: 100%;
  .input {
    height: 45px;
    max-height: 45px;
    @include flex($justify: space-between);
    padding: 0 0.3rem 0 1rem;
  }
  button {
    height: 85% !important;
    color: white !important;
  }
}
input:not([type="checkbox"]):not([type="radio"]),
select,
.input,
textarea,
.select-mui {
  background: transparent;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  padding: 0 1rem;
  border-radius: inherit;
  font-size: 12px;
  flex-grow: 2;
  line-height: 14px;
  color: var(--textClr);
  border: 1px solid var(--inputBorder);
  &:focus {
    border: 1px solid #a0aec0;
    outline: none;
  }
}
textarea {
  border-radius: 16px !important;
  padding: 15px !important;
}
::placeholder {
  color: #b5b5bd;
}
// material ui select
.select-mui {
  padding: 0;
  border-radius: 30px !important;
  background-color: var(--whiteClr);
  font-size: 12px !important;
  svg {
    fill: var(--iconBg);
  }
  &,
  input {
    border: none !important;
  }
  fieldset {
    border-radius: 30px;
    border-width: 1px !important;
    border-color: var(--inputBorder);
    &:focus {
      border-color: var(--primary) !important;
    }
  }
}
.menu-item {
  font-size: 12px !important;
  &.placeholder {
    color: #b5b5bd;
  }
  &:hover,
  &.Mui-selected {
    background-color: var(--primary) !important;
    color: var(--whiteClr) !important;
  }
}

.form-group textarea {
  height: 130px !important;
  padding: 0.5rem;
  max-height: unset;
  resize: vertical;
  cursor: default;
  &.extraHeight {
    height: 330px !important;
  }
}

.is-invalid {
  border-color: var(--red-100) !important;
}

.input-err-msg {
  color: #ff0606;
  width: 100%;
  font-weight: 500;
  font-size: 8.51965px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  text-align: right;
}

.commentInput {
  margin-bottom: 0;
  input,
  .input-icon-wrap {
    background: transparent;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    &:focus {
      background: transparent;
    }
  }
  .input-icon-wrap {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.06);

    svg path {
      fill: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

// phone style
.form-group {
  .react-tel-input {
    height: 100%;
  }
  .react-tel-input .selected-flag {
    height: 40px;
  }
  .react-tel-input .form-control {
    width: 100%;
  }
}
