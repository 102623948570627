@use "../../assets/styleUtils/index" as *;

.pd_navbar {
  padding: 10px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  background: rgba(22, 25, 44, 1);
  .logo {
    width: 150px;
  }
  .drop-down-action {
    p {
      color: white;
    }
    .css-1gnd1fd-MuiButton-endIcon {
      color: white;
    }
  }
}
