@use "./variables" as *;

// flex mixin
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

// media queries
@mixin mq2($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin mq($arg) {
  $size: map-get(
    $map: $breakpoint,
    $key: $arg,
  );

  @media only screen and (max-width: $size) {
    @content;
  }
}

// webkit scrollbar mixins
@mixin webkit {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    background: var(--primary);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: var(--primary);
  }
}

@mixin webkit2 {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
    background: #c4c4c4;
    border-radius: 30px;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    background: var(--whiteBg);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: var(--whiteBg);
  }
}

@mixin whiteContainer {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  width: 100%;
  max-height: 80vh;
  padding: 30px 0px 60px 0px;
}
@mixin modalLay {
  background: #ffffff;
  border-radius: 11px;
  padding: 1.5rem 30px;
  margin: auto;
  max-height: 90vh;
  overflow-y: auto;
}

@mixin search {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 21px;
  width: 250px;
  font-size: 14px;
  line-height: 21px;
  padding: 7px 10px;
  &::placeholder {
    font-size: 14px;
    line-height: 21px;
    color: #adadad;
  }
}
