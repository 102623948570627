.pd_settings {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tabs-wrapper {
    flex-grow: 2;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 0rem;
    padding: 1.5rem;
  }
  .btn-wrap {
    justify-content: flex-end;
  }
  .general-settings {
    max-width: 600px;
    .flex {
      align-items: center;
    }

    // .form-group {
    //   label {
    //     margin-bottom: 0;
    //     color: #323b43;
    //     font-weight: 200;
    //   }
    //   .input-icon-wrap,
    //   input {
    //     height: 40px;
    //     font-size: 1rem;
    //     padding: 0;
    //     font-weight: 500;
    //     border: none;
    //   }
    // }
  }
  .security-settings {
    max-width: unset;
    width: 90%;
    .flex {
      align-items: flex-start;
      .form-wrap {
        flex-grow: 2;
      }
      .title {
        width: 40%;
        h3 {
          font-size: 1rem;
          margin-bottom: 0.3rem;
          font-weight: 400;
        }
        p {
          color: #999999;
          font-size: 0.8rem;
        }
      }
    }
    .form-group-wrap {
      flex-wrap: wrap;
      .form-group {
        padding: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
