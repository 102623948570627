@use "../../assets/styleUtils/index" as *;

.pd_paper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  .paperSide {
    flex-grow: 2;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    padding: 34px 41px;
  }
}
