.not-found {
  min-height: 100vh;
  width: 100%;
  padding: 2rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    max-width: 300px;
    max-height: 60px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    min-height: 500px;
  }
  .content {
    text-align: center;
    max-width: 500px;
    h1 svg {
      font-size: 4rem;
    }
    h1.title {
      font-weight: 900;
      font-size: 2.5rem;
      @media (max-width: 700px) {
        font-size: 1.7rem;
      }
    }
    p {
      font-weight: 500;
      @media (max-width: 700px) {
        font-size: 0.8rem;
      }
    }
    .go-home-btn {
      min-width: 200px;
      margin-top: 0.5rem;
    }
  }
}
