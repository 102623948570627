@use "../../assets/styleUtils/index" as *;

.pd_sidebar {
  width: 250px;
  padding: 0 30px;
  position: sticky;
  top: 0;
  max-height: 100vh;
  height: max-content;
  .inner {
    width: 100%;

    .userBox {
      height: 100px;
      border: 1px solid #e0e0e0;
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: center;
      .userDet {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .name {
          p {
            color: #333333;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
          }
          margin-bottom: 5px;
        }
        .email {
          font-size: 12px;
          line-height: 10px;
          color: #4f4f4f;
        }
      }
    }
    .linkSide {
      height: 100%;
      overflow: auto;

      a.item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 24px;
        text-decoration: none;

        margin-bottom: 1rem;
        border-radius: 12px;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #718096;
          margin-left: 10px;
        }
        .iconSide {
          path,
          rect {
            stroke: #4f4f4f;
          }
        }
        &.active {
          background: white;
          p {
            color: var(--primary);
            font-weight: bold;
          }
          .iconSide {
            path,
            rect {
              stroke: var(--primary);
            }
          }
        }
      }
      .subLink {
        margin: 20px 0;
        .title {
          color: #9c9c9c;
          padding-left: 16px;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
